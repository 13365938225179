import React from 'react'
import { HashRouter, Link, Route, Routes } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { purple } from '@mui/material/colors';

import { FooterContainer } from '../containers/footer';
import logo from './../assets/images/logo.png';

import Home from '../components/Home';
import Contact from '../components/Contact';
import About from '../components/About';

// withStyles & makeStyles


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#dcc4ec !important',
        transform: 'translateZ(0)',
        position: 'relative',
    },

    logo: {
        //display: 'block',
        position: 'relative',
        width: '100%',
        maxWidth: '8rem',
        margin: '5px auto 5px auto',
        '&hover': {
            color: 'red',
        }
    },
    logo_a: {
        width: 'auto',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
    }
}))


export default function Header(props) {


    const classes = useStyles();

    // const menuId = 'primary-search-account-menu';

    return (
        <HashRouter>
            <>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" sx={{ background: purple[800]}}>
                        <Toolbar sx={{ display: { xs: 'block', sm: 'block' }, m: '0 auto', background: purple[800], }} >
                            <Link to="/" >
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'block', sm: 'block' }, m: '0 auto' }}
                                >
                                    <img className={classes.logo} src={logo} alt="Boutique do Suspiro Logo" />
                                </Typography>
                            </Link>

                            {/* <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 'auto' }} className="navbar-grid">
                                <MenuItem>
                                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
                                        <Link to="/about" className={classes.link}>
                                            <IconButton

                                                size="large"
                                                edge="end"
                                                aria-label="About us"
                                                aria-controls={menuId}
                                                aria-haspopup="true"

                                                color="inherit"
                                            >
                                                <InfoIcon sx={{ color: '#aaabb2', ml: 0 }} />
                                            </IconButton>
                                            <Typography
                                                className="menu-hover"
                                                variant="h6"
                                                noWrap
                                                component="div"
                                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#aaabb2', ml: 0, textDecoration: 'none' }}
                                            >
                                                {"Sobre"}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </MenuItem>

                                <MenuItem>
                                    <   Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                                        <Link to="/contact" className={classes.link}>
                                            <IconButton
                                                size="small"
                                                edge="end"
                                                aria-label="Contact us"
                                                aria-controls={menuId}
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                <ContactsIcon sx={{ color: '#aaabb2', ml: 0 }} />
                                            </IconButton>
                                            <Typography
                                                className="menu-hover"
                                                variant="h6"
                                                noWrap
                                                component="div"
                                                sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#aaabb2', ml: 0 }}
                                            >
                                                {"Contato"}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </MenuItem>

                            </Box> */}

                            <Box sx={{ flexGrow: 1 }} />

                        </Toolbar>
                    </AppBar>

                </Box>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                </Routes>
            </>

            <FooterContainer className="footer--pin" />

        </HashRouter>
    );
}

