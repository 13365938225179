import React from 'react'
// import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
// import Typography from '@mui/material/Typography';
import "./../assets/fonts/Gistesy.ttf";
import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { purple } from '@mui/material/colors';
// import InfoIcon from '@mui/icons-material/Info';
// import ContactsIcon from '@mui/icons-material/Contacts';
import foto from './../assets/images/Vanessa_photo.jpg';
import selo from './../assets/images/selo.png';
import file from './../assets/images/catalogo.pdf';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#dcc4ec!important',
    // transform: 'translateZ(0)',
    // position: 'relative',

  },
  button: {
    margin: '10px auto 10px auto!important',
    display: 'block!important',
    width: '20rem',
    padding: '2rem',
    fontSize: '1.15rem!important',
    '&:hover': {
      color: '#aaabb2!important',
      backgroundColor: '#dcc4ec!important',
    },
    '&:active':{
      color: '#aaabb2',
      backgroundColor: '#dcc4ec!important',
    }
  },
  foto: {
    display: 'inline-block',
    position: 'relative',
    width: '70%',
    maxWidth: '12rem',
    margin: '0px auto 5px auto',
    paddingTop: '60px',
    '&:hover': {
      color: '#dcc4ec',
    }
  },
  fotoselo: {
    display: 'inline-block',
    position: 'relative',
    width: '30%',
    margin: '0px auto 5px auto',
    maxWidth: '7rem',
    top: '-80px',
    left: '15px',
    '&:hover': {
      color: '#dcc4ec',
    }
  },
  home: {
    display: 'block',
    marginTop:  '8.5rem',
    textAlign: 'center',
    background: 'linear-gradient(-45deg, #6a1b9a, white, white, #6a1b9a)',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  }
}))

export default function Home() {

  const classes = useStyles();
  // const menuId = 'primary-search-account-menu';
  const site = 'https://boutiquedosuspiro.com';
  const whats = 'https://wa.me/5511990052502';

  return (
    <div className={classes.root}>
    <div className={ classes.home}>
      <a href="#/about"><img className={classes.foto} src={foto} alt="Vanessa Dini" /></a>
      <img className={classes.fotoselo} src={selo} alt="Selo Qualidade" />
      <h1 className="nome">Boutique do Suspiro</h1>
      <a href="#/about"><h3 className="by-nome">By Vanessa Dini</h3></a>

      <Button className={classes.button} sx={{ background: purple[800] }} href={file} target="_blank" rel="noopener noreferrer"  variant="contained">Catalogo de Natal</Button>
      <Button className={classes.button} sx={{ background: purple[800] }} href={site} target="_blank" rel="noopener noreferrer" variant="contained">Nosso Site</Button>
      <Button className={classes.button} sx={{ background: purple[800] }} href={whats} target="_blank" rel="noopener noreferrer" variant="contained">Fale Conosco</Button>
    <hr></hr>
        <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 'auto' }} className="navbar-grid">

          {/* <MenuItem>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
              <Link to="/about" className={classes.link}>
                <IconButton

                  size="large"
                  edge="end"
                  aria-label="About us"
                  aria-controls={menuId}
                  aria-haspopup="true"

                  color="inherit"
                >
                  <InfoIcon sx={{ color: '#aaabb2', ml: 0 }} />
                </IconButton>
                <Typography
                  className="menu-hover"
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'none', sm: 'block' }, color: '#aaabb2', ml: 0, textDecoration: 'none' }}
                >
                  {"Sobre"}
                </Typography>
              </Link>
            </Box>
          </MenuItem>

          <MenuItem>
            <   Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
              <Link to="/contact" className={classes.link}>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="Contact us"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ContactsIcon sx={{ color: '#aaabb2', ml: 0 }} />
                </IconButton>
                <Typography
                  className="menu-hover"
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#aaabb2', ml: 0 }}
                >
                  {"Contato"}
                </Typography>
              </Link>
            </Box>
          </MenuItem> */}

        </Box>
      </Box>
        <Box sx={{ flexGrow: 1 }} />

    </div>
    </div>
  )
}
