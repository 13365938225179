import React from 'react'
import { purple } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';




export default function About() {
  const site = 'https://boutiquedosuspiro.com';
  return (
    <>

      <Card sx={{ maxWidth: 345, m: '0 auto', color: '#6a219a', margin: '164px auto 0 auto' }}>
        <CardMedia
          sx={{ height: 140 }}
          image="./images/superacao.jpg"
          title="Superação"
        />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            De Suspiros a Superações
        </Typography>
        <Typography variant="body2" sx={{color: purple[800], fontSize: '1.1rem'}}>
            A inspiradora jornada de <b>Vanessa Dini</b>, uma empreendedora que transformou a dor em doçura.
            Graduada em Turismo, Vanessa enfrentou um desafio devastador quando seu pai, após um transplante de fígado, não recebeu o cuidado que merecia e terminou por falecer.
            Determinada a preencher lacunas no sistema de saúde, ela buscou uma carreira médica. Estudou em silêncio por 2 anos em busca
            do nobre objetivo de não só doar seus conhecimentos, mas também atitudes de conforto e esperança a seus futuros pacientes.
            Contudo, a pandemia de COVID-19 alterou seus planos, levando-a a uma trajetória inesperada.
            Vanessa não se resignou; em vez disso, fundou a <b>Boutique do Suspiro</b>, oferecendo obras de arte em forma de Suspiros Gourmet.
            Este pequeno texto narra não apenas a criação da Boutique, mas também os planos audaciosos de Vanessa para expandir sua doçura pelo país,
            um suspiro de esperança em tempos desafiadores.
            Descubra como Vanessa transformou adversidades em oportunidades, trazendo um toque especial a eventos especiais e construindo um legado doce em meio à incerteza
        </Typography>
      </CardContent>
      <CardActions>
          <Button size="small" href={site} target="_blank" rel="noopener noreferrer"  sx={{ color: '#ac9551', textAlign: 'center'}}>Saiba mais</Button>
          <Button size="small" href="#/" sx={{ color: '#ac9551', textAlign: 'center' }}>Retornar</Button>
      </CardActions>
    </Card>
    <br />
    </>
  )
}
