import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';


import contact from '../assets/images/contact_purple.png';


export default function Contact() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])

    const [state, setState] = useState({
        name: "",
        lastname: "",
        email: '',
        phone: '',
        reason: '',
        message: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        var template_params = {
            name: state.name,
            lastname: state.lastname,
            phone: state.phone,
            from_name: state.email,
            to_name: 'Olá time da Boutique do Suspiro',
            subject: 'Visitou a Boutique do Suspiro',
            reason: state.reason,
            message_html: state.message,
        }

        var service_id = "contact_service";
        var template_id = "template_ex836fg";
        var user_id = 'G6jYL3-Jxy_YNy4Xz'; //'2FZ9hkxvRt-6Y4sVG'; //user_7kPkOhEo0c6N7DHtmJ9o7'


        if ((state.name === '') || (state.email === '') || (state.phone === '') || (state.reason === '')) {
            document.getElementById('message-error').innerHTML = "Por favor entre com os dados marcados com *!";
        } else {
            emailjs.send(service_id, template_id, template_params, user_id)
                .then((result) => {
                    //console.log(result.text);
                    document.getElementById('message-error').innerHTML = "Email Enviado!";
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                });
        }
    }

    const handleChange = ( name, event ) => {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }


    const resetForm = () => {
        setState({ name: '', lastname: '', email: '', phone: '', reason: '', message: '' })
    }


    return (
        <React.Fragment>
            <section id="contact">
                <div className="container-wrap">
                    <br /><br /><br />
                    <div className='contact-header'>{"Entre em Contato"}</div>
                    <img className="contact-image img-fluid" src={contact} alt="Contact us" />
                </div>

                <div id="form">

                    <form id="waterform" method="POST" onSubmit={handleSubmit}>

                            <div className="grid-contact-item1">
                                <div>
                                    <div className="" id="name-form">
                                        <label className="contact-label" htmlFor="name">{"Nome *"}</label>
                                        <input className="contact-input" type="text" id="name" value={state.name} name="name" onChange={(event) => handleChange('name', event)} />
                                    </div>

                                    <div className="" id="lastname-form">
                                        <label className="contact-label" htmlFor="lastname">{"Sobrenome"}</label>
                                        <input className="contact-input" type="text" id="lastname" value={state.lastname} name="lastname" onChange={(event) => handleChange('lastname', event)} />
                                    </div>

                                    <div className="" id="email-form">
                                        <label className="contact-label" htmlFor="email">{"Email *"}</label>
                                        <input className="contact-input" type="email" id="email" value={state.email} name="email" onChange={(event) => handleChange('email', event)} />
                                    </div>

                                    <div className="" id="phone-form">
                                        <label className="contact-label" htmlFor="phone">{"Celular *"}</label>
                                        <input className="contact-input" type="text" id="phone" value={state.phone} name="phone" onChange={(event) => handleChange('phone', event)} />
                                    </div>

                                    <div>
                                        <label className="contact-label" htmlFor="sel1">{"Razão do Contato *"}</label>
                                        <select className="form-control contact-select" id="sel1" value={state.reason} sx={{ color: 'red' }} name="reason" onChange={(event) => handleChange('reason', event) }>
                                            <option className="contact-input" active="true">{"Selecione uma opção"}</option>
                                            <option className="contact-input">{"Corporativo"}</option>
                                            <option className="contact-input">{"Batizados"}</option>
                                            <option className="contact-input">{"Nascimentos"}</option>
                                            <option className="contact-input">{"Aniversário"}</option>
                                            <option className="contact-input">{"Bodas"}</option>
                                            <option className="contact-input">{"Outro"}</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="contact-label" htmlFor="message">{"Sua Mensagem"}</label>
                                        <textarea className="contact-textarea" id="message" value={state.message} name="message" onChange={(event) => handleChange('message', event)}></textarea>
                                    </div>

                                    <div>
                                        <input className="contact-submit" type="submit" value={"Enviar"} />
                                    </div>

                                    <div>
                                        <label id="message-error" className="message-error" type="text" />
                                    </div>
                                </div>
                            </div>

                    </form>
                </div>

            </section>
        </React.Fragment>);

    }
